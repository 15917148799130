<template>
  <v-dialog
    v-model="dialogTutorial"
    width="344"
  >
    <v-card
      max-width="344"
      tile
    >
      <iframe
        :src="tutorial.video"
        :title="tutorial.title"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
        height="210"
        width="100%"
      />

      <v-card-subtitle class="mt-2">
        Precisa de ajuda? Assista ao tutorial acima e verifique como: <b>{{ tutorial.genericTitle || tutorial.title }}</b>.
      </v-card-subtitle>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="orange lighten-2"
          style="text-transform: none !important"
          block
          outlined
          text
          @click="addTutorialToBlacklist"
        >
          Não exibir esse tutorial novamente
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import { ref } from '@vue/composition-api'

export default {
  props: {
    tutorialKey: {
      type: String,
      required: true,
    },
  },

  setup() {
    const dialogTutorial = ref(false)
    const tutorial = ref(null)

    return {
      dialogTutorial,
      tutorial,
    }
  },

  computed: {
    ...mapState('app', [
      'tutorials',
    ]),
  },

  created() {
    const tutorial = this.getTutorial()
    if (!tutorial) {
      this.dialogTutorial = false

      return
    }

    const tutorialBlacklist = String(localStorage.getItem('tutorialBlacklist') || '').split(',')
    if (tutorialBlacklist.includes(tutorial.key)) {
      this.dialogTutorial = false

      return
    }

    this.dialogTutorial = true
    this.tutorial = tutorial
  },

  methods: {
    getTutorial() {
      return this.tutorials.find(tutorial => tutorial.key === this.tutorialKey)
    },

    addTutorialToBlacklist() {
      const tutorialBlacklist = String(localStorage.getItem('tutorialBlacklist') || '').split(',')
      tutorialBlacklist.push(this.tutorial.key)
      localStorage.setItem('tutorialBlacklist', tutorialBlacklist.join(','))

      this.dialogTutorial = false
    },
  },
}
</script>
